@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Bebas Neue';
    src: ('BebasNeueBold.woff2') format('woff2'),
        ('BebasNeueBold.woff') format('woff'),
        ('BebasNeueBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
* {

  font-family: 'Bebas Neue', sans-serif;
}

p {
  font-family: 'Poppins', sans-serif;
}

.App {
    text-align: center;
  }

  .margin-link
  {
    position: relative;
    margin-left: 30px;
  }

  .espacamente-link{
    position: relative;
    margin-right: 10%;
  }
  .App-logo {
    width: 175px;
    height: 70px;

  }
  .nav-link1{
    font-size: 17px;
      letter-spacing: 0;
      line-height: 1.6em;
      font-family: Poppins,sans-serif;
      font-weight: 600;
      color: #1a2942;
      text-decoration: none;
      position: relative;
      top: 0px;
      margin-right: 25px;
  }
  .size-input-header{
    width: 600px;
    text-align: center;
    position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      text-align: left;

  }


  .circle-wallet{
      width: 13px;
      height: 13px;
      border-radius: 50%;
  }

  .color-circle-red{
    background-color: #ffbfbf;
    border-style: solid;
    border-bottom-width: 2px;
    border-top-width: 2px;
    border-right-width: 2px;
    border-left-width: 2px;
    border-color: red;
  }

  .texting-right-circle{
    font-weight: bold;
    position: relative;
    margin-left: 5px;
    font-size: 13px;
    color:black;
  }


.sombra{
    width: 100%;
    margin: auto;

    border-bottom: 1px solid rgb(218, 218, 218);
}




.textspanexplore{
    position: relative;
    top: 30px;
    font-size: 36px;
    font-weight: bold;

}

.textspanexplore2{
    position: relative;
    top: 30px;
    font-size: 20px;
    color: rgb(146, 146, 146);
}



.alignposcon{
   text-align: left;
    position: relative;
    left:29%;
    top:35px;
}

.espacali{
    height: 65px;
}





.App-ico{
    position: relative;
    height: 30px;
    width: 30px;
    top: 9px;
    left: 10px;
}

.fonttitulomask{
    position: relative;
    top: 12px;
    left:25px;
    font-size: 19px;
    font-weight: bold;
}


.textshowmore{
    position: relative;
    text-align: center;
    left: 36%;
    top:8px;
    font-size: 19px;
    color: gray;
    font-weight: bold;
}

  .footerstyle{
    position: relative;
    top: 150px;
    background-color: #2ebed5;
    height: 550px;

  }


  .textfooter{
    font-size: 19px;
    color: white;
    font-weight: bold;
    text-align: center;
    position: relative;
    top: 45px;
    text-align: left;
  }

  .textfooterdown{
    font-size: 15px;
    color: white;

    text-align: center;
    position: relative;
    top: 35px;
    text-align: left;


  }
  .inputmail{
    width: 80%;
    margin-top: 30px;
  }
  .buttonsendmail{
    color: white;
    font-weight: bold;
    padding-top:8px;
    position: relative;
    margin-left: 5px;
    background-color: #59ddf1;
    height: 40px;
    width: 115px;
    border-radius: 5px;
    margin-top: 30px;
  }

  .espacetext{
    position: relative;
    margin-left: 50px;
  }
  .boxespacamentosocial{
    position: relative;
    margin-left: 50px;
  }
  .boxsocialredes{
    position: relative;
    top: 40px;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-left: 10px;
    background-color: #59ddf1;
    color: white;
    padding-top: 10px;
  }

  .changehr{
    background-color: white;

  }



  .text-reset{
    color: white !important;
    text-decoration: none;

  }

  .text-reset1{
    color:white;

  }

  .fontnaf2021{
    color: white;
    text-align: left;
  }

  .fontpri2021{
    position: relative;
    top: -27px;
    color: white;
    text-align: right;

  }

  .privc{
    position:relative; margin-right:15px;
  }


  /*** custom css ****/
  .margin-0{
    margin: 0 auto;
  }
